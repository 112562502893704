import React from "react"

const PageNotFound = () => (
  <>
    <h1 style={{ width: "100%", textAlign: "center" }}>NOT FOUND</h1>
    <p style={{ width: "100%", textAlign: "center" }}>
      You just hit a route that doesn't exist... the sadness.
    </p>
  </>
)

export default PageNotFound
